#root {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}
body {
    margin: 0;
}

::-webkit-scrollbar-track {
    box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.3);
}
::-webkit-scrollbar-thumb {
    background-color: #d9d9d9;
    border: 1px solid #c8c8c8;
    /*box-shadow: 0 0 1px rgba(0, 0, 0, 0.3);*/
    border-radius: 20px;
}
::-webkit-scrollbar {
    width: 5px;
    height: 10px;
}

.ms-DetailsList {
    margin-top: 16px;
    border: 0.5px solid #f3f2f1;
    width: 100%;
}
.ms-DetailsHeader {
    border-bottom: unset;
    padding-bottom: unset;
    padding-top: unset;
}
.ms-DetailsHeader-cell {
    border: 0.5px solid #f3f2f1;
    color: gray;
}
.ms-DetailsRow-cell {
    border: 0.5px solid rgb(243 242 241);
}
.ms-DetailsRow {
    border-bottom: unset;
}
.ms-TextField-fieldGroup, .ms-Dropdown-title, .ms-BasePicker-text, .ms-Checkbox-checkbox {
    border-color: #dadada;
}
.ms-DetailsHeader-cellName {
    font-weight: 400;
}
.ms-Button--default {
    border-color: #dadada;
}
.ms-Button.is-disabled:hover {
    background-color: rgb(243, 242, 241);
    color: rgb(208, 208, 208);
}
.ms-Nav {
    width: 190px;
}
.paginationButton {
    width: 50px;
    min-width: unset;
}
.noBorderList, .noBorderList .ms-DetailsRow-cell {
    border: none;
}
.ms-DetailsList.noMarginTop {
    margin-top: 5px;
}
.editableTable .ms-List-cell, .editableTable .ms-DetailsRow {
    min-height: 30px;
    height: 33px;
}
.editableTable .ms-DetailsRow-cell {
    padding: 0;
    min-height: 30px;
}
.editableTable .ms-TextField-fieldGroup, .editableTable .ms-Dropdown-title {
    border: none;
}
.ms-Button:hover {
    background-color: #edebe9;
    color: black;
}
.ms-Button--primary:hover {
    background-color: #d8491e;
    color: white;
}
.ms-DetailsRow-cell[data-automation-key="fileType"], .ms-DetailsRow-cell[data-automation-key="fileName"] {
    padding-left: 0;
}
.ms-DetailsRow-cell[data-automation-key="avatar"] {
    padding-left: 6px;
}
.ms-Breadcrumb-list > .ms-Breadcrumb-listItem:first-child > .ms-Breadcrumb-itemLink {
    padding-left: 0;
}
.ms-Breadcrumb-itemLink:hover, .ms-Breadcrumb-itemLink:active, .ms-Breadcrumb-itemLink:active:hover {
    background-color: transparent;
}
.ms-Callout-main a {
    text-decoration: none;
    color: #ef5021;
}

.DonutCalloutContentRoot {
    display: grid;
    overflow: hidden;
    padding: 11px 16px 10px;
    background-color: rgb(255, 255, 255);
    background-blend-mode: normal, luminosity;
}
.DonutCalloutDateTimeContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.DonutCalloutContentX {
    font-family: "Segoe UI Web (Cyrillic)", "Segoe UI", -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    opacity: 0.85;
    color: rgb(153, 153, 153);
}
.DonutCalloutBlockContainer {
    font-family: "Segoe UI Web (Cyrillic)", "Segoe UI", -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    font-size: 28px;
    font-weight: 600;
    margin-top: unset;
    padding-left: 8px;
    line-height: 22px;
    color: rgb(29, 29, 29);
    border-left: 4px solid;
}
.DonutCalloutLegendText {
    font-family: "Segoe UI Web (Cyrillic)", "Segoe UI", -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    color: rgb(29, 29, 29);
}
.DonutCalloutContentY {
    font-family: "Segoe UI Web (Cyrillic)", "Segoe UI", -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    font-size: 28px;
    font-weight: bold;
    line-height: 36px;
}
